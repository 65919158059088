import {
	useDeleteAssignments,
	useStepInstanceAssignments,
	useUpdateAssignments,
} from "@metronome/api/useAssignments";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@metronome/components/ui/dialog";
import type { RoleAndPartyAssignments } from "@metronome/types/PartyAssignment";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import { Button } from "@metronome/components/ui/button";
import {
	CheckCircledIcon,
	CheckIcon,
	Cross2Icon,
	LapTimerIcon,
	PersonIcon,
} from "@radix-ui/react-icons";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuPortal,
	DropdownMenuSeparator,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from "@metronome/components/ui/dropdown-menu";
import { Badge } from "@metronome/components/ui/badge";
import { AvailablePartiesModal } from "./AvailablePartiesModal";

type AddPartyDialogProps = {
	role: RoleAndPartyAssignments["role"];
	stepInstanceId: string;
	nbAssignments: number;
	targetCount: number;
	applicationLevel: RoleAndPartyAssignments["applicationLevel"];
};

const AddPartyDialog: React.FC<AddPartyDialogProps> = ({
	role,
	stepInstanceId,
	nbAssignments,
	targetCount,
	applicationLevel,
}) => {
	const [open, setOpen] = useState(false);

	return (
		<Dialog open={open} onOpenChange={setOpen} modal={true}>
			<DialogTrigger asChild>
				<Button variant="outline">
					<FormattedMessage id="ADD" />
					<span
						className={
							nbAssignments < targetCount
								? "text-red-600 ms-2"
								: "text-primary ms-2"
						}
					>
						{`${nbAssignments}/${targetCount}`}
					</span>
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						<FormattedMessage id={"ADD_ASSIGNEE"} />
					</DialogTitle>
				</DialogHeader>
				<AvailablePartiesModal
					roleId={role.id}
					stepInstanceId={stepInstanceId}
					applicationLevel={applicationLevel}
					onSuccess={() => setOpen(false)}
				/>
			</DialogContent>
		</Dialog>
	);
};

const AssigneesTable: React.FC<{
	assignments: RoleAndPartyAssignments["assignments"];
	stepInstanceId: string;
	definitionLevel: RoleAndPartyAssignments["definitionLevel"];
	applicationLevel: RoleAndPartyAssignments["applicationLevel"];
	roleId: string;
}> = ({
	assignments,
	stepInstanceId,
	definitionLevel,
	applicationLevel,
	roleId,
}) => {
	const { mutate: deleteAssignment } = useDeleteAssignments(
		"step-instances",
		stepInstanceId,
	);
	const { mutate: updateAssignment } = useUpdateAssignments(
		"step-instances",
		stepInstanceId,
	);
	if (assignments.length === 0) {
		return (
			<div className="w-full border border-dashed border-slate-400 p-3 rounded">
				<FormattedMessage id="NO_ASSIGNMENTS_YET" />
			</div>
		);
	}
	return (
		<div className=" flex flex-col gap-2 ">
			{assignments.map((a) => (
				<DropdownMenu modal={false} key={a.id}>
					<DropdownMenuTrigger>
						<div className="flex items-center gap-2 rounded-sm">
							<Badge variant="secondary">
								<PersonIcon className="me-1" />
								{a.party.lastName} {a.party.firstName}
								{a.status === "acknowledged" ? (
									<CheckCircledIcon className="inline text-primary ms-1" />
								) : (
									<LapTimerIcon className="inline text-blue-600 ms-1" />
								)}
							</Badge>

							<div>
								{a.origin !== "dispatch" && (
									<span className="text-slate-600 italic">{a.origin}</span>
								)}
							</div>
						</div>
					</DropdownMenuTrigger>
					<DropdownMenuContent className="w-48">
						<DropdownMenuLabel>
							{a.party.lastName} {a.party.firstName}
						</DropdownMenuLabel>
						<DropdownMenuSeparator />
						<DropdownMenuSub>
							<DropdownMenuSubTrigger>
								<CheckIcon className="me-2" />
								<FormattedMessage id="AVAILABILITY" />
							</DropdownMenuSubTrigger>
							<DropdownMenuPortal>
								<DropdownMenuSubContent>
									<DropdownMenuItem
										onSelect={() =>
											updateAssignment({
												assignmentId: a.id,
												applicationLevel,
												active: true,
												partyId: a.party.id,
												roleId,
											})
										}
									>
										<FormattedMessage id="AVAILABLE" />
									</DropdownMenuItem>
									<DropdownMenuItem
										onSelect={() =>
											updateAssignment({
												assignmentId: a.id,
												applicationLevel,
												active: false,
												partyId: a.party.id,
												roleId,
											})
										}
									>
										<FormattedMessage id="UNAVAILABLE" />
									</DropdownMenuItem>
								</DropdownMenuSubContent>
							</DropdownMenuPortal>
						</DropdownMenuSub>

						<Dialog>
							<DialogTrigger asChild>
								<DropdownMenuItem onSelect={(e) => e.preventDefault()}>
									<PersonIcon className="me-2" />
									<FormattedMessage id="PROFILE" />
								</DropdownMenuItem>
							</DialogTrigger>
							<DialogContent>
								{a.party.firstName} {a.party.lastName}
								<div>{a.party.email}</div>
							</DialogContent>
						</Dialog>
						{a.origin === "regulation" && definitionLevel === "step" && (
							<>
								<DropdownMenuSeparator />
								<DropdownMenuItem
									onClick={() =>
										deleteAssignment({
											assignmentId: a.id,
											applicationLevel: applicationLevel,
										})
									}
									className="hover:text-red-500 group"
								>
									<Cross2Icon className="me-2 group-hover:text-red-500" />
									<span className="group-hover:text-red-500 truncate">
										<FormattedMessage id="REMOVE" />
									</span>
								</DropdownMenuItem>
							</>
						)}
					</DropdownMenuContent>
				</DropdownMenu>
			))}
		</div>
	);
};

export const AssigneesTableByRole: React.FC<{ stepInstanceId: string }> = ({
	stepInstanceId,
}) => {
	const { data: assignmentsMap, isLoading } =
		useStepInstanceAssignments(stepInstanceId);
	if (isLoading) return null;
	return (
		<div className={`grid grid-cols-${assignmentsMap.length} gap-4`}>
			{assignmentsMap?.map(
				({
					role,
					assignments,
					targetCount,
					definitionLevel,
					applicationLevel,
				}) => (
					<div key={role.id} className="flex flex-col max-w-96">
						<div className="flex gap-4 items-center py-2 justify-start">
							<span className="uppercase font-semibold">{role.name}</span>
							<AddPartyDialog
								role={role}
								stepInstanceId={stepInstanceId}
								nbAssignments={assignments.length}
								targetCount={targetCount}
								applicationLevel={applicationLevel}
							/>
						</div>
						<AssigneesTable
							assignments={assignments}
							stepInstanceId={stepInstanceId}
							definitionLevel={definitionLevel}
							applicationLevel={applicationLevel}
							roleId={role.id}
						/>
					</div>
				),
			)}
		</div>
	);
};
